<template>
  <div>
    <vx-card>
      <ChildrenStoriesSetSideBar
        v-if="isSidebarActive"
        :isSidebarActive="isSidebarActive"
        @refreshData="toggleRefreshData"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
        :dataId="dataId"

      />
      <div class="mb-8">
        <h2 class="mb-0">Children's Stories</h2>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col md:w-1/2 w-full" align="left">
          <vs-input
            class="w-full search-input"
            v-model="searchQuery"
            placeholder="Search..."
          />
        </div>
        <div class="vx-col md:w-1/2" align="right">
          <vs-button
            class="mb-md-0 mb-2"
            align="right"
            @click="addNewSetHandler"
            >Add New Set
          </vs-button>
        </div>
      </div>

      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="storiesData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
         @selected="handleSelected"
      >
        <template slot="thead">
          <vs-th class="justify-center">Title</vs-th>
          <vs-th>Description</vs-th>
          <vs-th>Learning Centre</vs-th>
          <vs-th>Number of Questions</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.title" class="text-center">
              {{ tr.title | capitalize }}
            </vs-td>
            <vs-td :data="tr.description">
              {{ tr.description }}
            </vs-td>
            <vs-td
              :data="
                tr.centreDetails && tr.centreDetails.length > 0
                  ? tr.centreDetails[0].name
                  : 'Default'
              "
            >
              {{
                tr.centreDetails && tr.centreDetails.length > 0
                  ? tr.centreDetails[0].name
                  : "Default" | capitalize
              }}
            </vs-td>
            <vs-td :data="tr.questionDetails.length">
              {{ tr.questionDetails.length }}
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <a href="#" @click.stop="viewDetailHandler(tr._id)">
                <feather-icon
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                />
              </a>
              <a href="#" @click.stop="editDetailHandler(tr, tr._id)">
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
              </a>
              <a href="#" @click.stop="deleteCenterData(tr._id)">
                <feather-icon
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                />
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="my-5" v-if="storiesData.length > 0">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
      <br />
      <!--      <a class="m-3 text-bold underline" style="cursor: pointer;font-size: medium" @click=addNewStoryHandler>Add New</a>-->
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import ChildrenStoriesSetSideBar from "./addSetSidebar";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  components: {
    ChildrenStoriesSetSideBar,
    "v-select": vSelect
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        limit: 10,
        page: 1
      },
      storiesData: [],
      limitOptions: ["5", "10", "25", "50", "100"],
      serverResponded: false,

      //add story sidebar
      isSidebarActive: false,
      sidebarData: {},
      dataId: "",

      popUpDelete: false,
      activeConfirm: false,
      questionId: "",
      editStory: false,
      searchQuery: ""
    };
  },
  methods: {
    ...mapActions("childrenStories", [
      "getChildrenStoriesSetList",
      "deleteChildrenStoriesSet",
      "saveChildrenStory"
    ]),
    handleSelected(tr) {
      console.log('asdsad')
      this.viewDetailHandler(tr._id);
    },
    getSetList() {
      this.dataTableParams.search = this.searchQuery;
      this.getChildrenStoriesSetList(this.dataTableParams).then(res => {
        this.storiesData = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSetList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getSetList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSetList();
    },
    viewDetailHandler(id) {
      this.$router.push({
        name: "learning-center-presets-children-stories-question-list",
        params: { id: id }
      });
    },
    editDetailHandler(data, id) {
      this.sidebarData = data;
      this.dataId = id;
      this.toggleDataSidebar(true);
    },
    deleteCenterData(id) {
      Swal.fire({
        title: "Are you sure you want to delete this set?",
        text:
          "Once you delete set, all questions included on set will also be deleted. You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.acceptDeleteAlert(id);
        }
      });
    },
    acceptDeleteAlert(id) {
      this.$vs.loading();
      this.deleteChildrenStoriesSet(id)
        .then(res => {
          if (res.status === 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Question deleted successfully.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            this.getSetList();
          }
        })
        .catch(err => {
          this.$vs.loading.close();
          // console.error(err)
        });
    },
    addNewSetHandler() {
      this.sidebarData = {};
      this.dataId = "";
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.isSidebarActive = val;
    },
    toggleRefreshData(val = false) {
      if (val) {
        this.getSetList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getSetList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getSetList();
      }
    },
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getSetList();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    }
  },
  created() {
    this.getSetList();
  }
};
</script>
<style>
@media (max-width: 576px) {
  .vs-table--header {
    flex-wrap: wrap;
  }
  .vs-table--header .vs-table--search,
  .vs-table--header .vs-table--search .vs-table--search-input {
    max-width: 100%;
    width: 100%;
  }

  .vs-table--header .vs-table--search .vs-table--search-input {
    margin-left: inherit;
  }
}
</style>
