<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Story</h3>
      <!--      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>-->
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <!--    <VuePerfectScrollbar class="scroll-area&#45;&#45;data-list-add-new" :settings="settings" :key="$vs.rtl">-->
    <div class="p-6">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left">
          <div class="w-full mb-5">
            <vs-input
              label="Title"
              v-model="questionData.title"
              class="w-full"
              name="title"
              v-validate="'required'"
              data-vv-as="Title"/>
            <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first('title') }}</span>
          </div>
        </vs-col>
        <label class="block mb-2">Description</label>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left">
          <div class="w-full mb-5">
            <vs-textarea
              :success="
                !errors.first('description') && questionData.description != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('description') ? true : false"
              v-validate="'required'"
              name="description"
              data-vv-as="Description"
              icon-no-border
              icon="icon icon-book"
              icon-pack="feather"
              class="w-full"
              v-model="questionData.description"
            />
            <span class="text-danger text-sm">{{ errors.first("description") }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="vx-col w-full">
            <label class="block mb-2">Learning Centre</label>
            <v-select :options="learningCentreOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="learningCentreFilter" class="mb-4 md:mb-0"/>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <!--    </VuePerfectScrollbar>-->

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button
        :disabled="!validateForm"
        @click="submitHandler"
        class="mr-6"
      >Submit
      </vs-button>
      <vs-button class="vs-button-secondary" @click="cancelHandler()" align="right">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {mapActions} from "vuex";
import vSelect from 'vue-select'

export default {
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  data() {
    return {
      questionData: {
        title: "",
        description: "",
        learningCenterId: ""
      },

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 100,
        wheelSpeed: .60,
      },
      id: "",
      title: "",
      description: "",
      learningCenterId: "",
      learningCentreOptions: [],
      learningCentreFilter: {label: 'All', value: ''},
    }
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      },
    },
    dataId: {
      type: String,
      default: ""
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.questionData.title = '';
        this.questionData.description = '';
        this.$validator.reset()
      } else {
        this.id = this.dataId
        this.questionData.title = this.data.title
        this.questionData.description = this.data.description
        this.learningCentreFilter = {label: this.data.centreDetails[0].name, value: this.data.centreDetails[0]._id};
        this.initValues()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.questionData.title != "" &&
        this.questionData.description != ""
      );
    },
  },
  methods: {
    ...mapActions("childrenStories", [
      "saveChildrenStorySet",
      "editChildrenStorySet",
      "fetchLearningCentreList"
    ]),
    initValues() {
      if (this.id) return
      this.id = ""
      this.title = ""
      this.description = ""
      this.learningCentreFilter = {label: 'All', value: ''}
    },
    async submitHandler() {
      this.$vs.loading();
      this.questionData.learningCenterId = this.learningCentreFilter.value;
      if (this.dataId !== "") {
        const info = {
          id: this.data._id,
          details: this.questionData
        };
        await this.editChildrenStorySet(info).then(res => {

          this.$vs.notify({
            title: 'Success',
            text: "Children stories set edited Successfully.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.$vs.loading.close();
          this.learningCentreFilter = {label: 'All', value: 'all'}
          this.$emit('refreshData', true);
          this.$emit('closeSidebar');

        })
          .catch(err => {
            this.$vs.loading.close();
            if (err.status === 405) {
              this.$vs.notify({
                title: 'Failure',
                text: err.msg,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
            }
            this.$vs.notify({
              title: 'Failure',
              text: "Children stories set could not be edited.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
            this.$emit('closeSidebar');

          })
      } else {
        await this.saveChildrenStorySet(this.questionData).then(res => {
          this.$vs.notify({
            title: 'Success',
            text: "Children stories set created Successfully.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.$vs.loading.close();

          this.learningCentreFilter = {label: 'All', value: ''}
          this.$emit('refreshData', true);
          this.$emit('closeSidebar');
        })
          .catch(err => {
            this.$vs.loading.close();
            if (err.response.status === 405 || err.response.status === 422) {
              this.$vs.notify({
                title: 'Failure',
                text: err.response.data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
            } else {
              this.$vs.notify({
                title: 'Failure',
                text: "Children stories set could not be created.",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
              this.$emit('closeSidebar');
            }
          })
      }
    },
    cancelHandler() {
      this.isSidebarActiveLocal = false;
      this.questionData.title = "";
      this.questionData.description = "";
      this.learningCentreFilter = {label: 'All', value: ''}
    },
  },
  beforeMount() {
    this.errors.clear();
    this.$nextTick(() => {
      this.$validator.reset();
    });
  },
  async created() {
    if (Object.entries(this.data).length === 0) {
      this.initValues();
      this.questionData.title = '';
      this.questionData.description = '';
      this.$validator.reset()
    } else {
      this.id = this.dataId
      this.questionData.title = this.data.title
      this.questionData.description = this.data.description
      if (this.data.centreDetails && this.data.centreDetails.length > 0) {
        this.learningCentreFilter = {label: this.data.centreDetails[0].name, value: this.data.centreDetails[0]._id};
      }
      this.initValues()
    }
    await this.fetchLearningCentreList().then(res => {
      let data = res.data.data;
      this.learningCentreOptions = data.map((item) => {
        return {
          label: item.name,
          value: item._id
        }
      })
    });
  }

}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
